@tailwind base;
@tailwind components;
@tailwind utilities;

.button {
    @apply bg-emerald-100 p-2 rounded-lg shadow-md;
}

.text-block {
    @apply  w-full h-full rounded-sm drop-shadow-sm flex flex-col bg-gray-100 placeholder:text-gray-50;
}

.ederpad-input {
    @apply h-12 px-4 bg-gray-100 focus:outline-none focus:border-transparent focus:bg-gray-50 hover:bg-gray-50 transition-all duration-300;
}

.smart-block {
    @apply min-w-[200px] w-fit h-full p-4 rounded-lg shadow-md flex flex-col bg-emerald-100 text-emerald-900;
}

